import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3",
          attrs: {
            color: "primary",
            icon: "mdi-office-building",
            title: _vm.$t("manage_thesis"),
          },
        },
        [
          _vm.loading
            ? _c(
                VRow,
                {
                  staticClass: "pa-4",
                  attrs: { justify: "center", align: "center" },
                },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", size: "30", color: "primary" },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.thesis.Id
                    ? _c(
                        VTabs,
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(VTab, [_vm._v(_vm._s(_vm.$t("basic")))]),
                          _c(VTab, [_vm._v(_vm._s(_vm.$t("highlights")))]),
                          _c(VTab, [_vm._v(_vm._s(_vm.$t("files")))]),
                          _c(VTab, [_vm._v(_vm._s(_vm.$t("dxa_vision")))]),
                          _c(VTab, [
                            _vm._v(_vm._s(_vm.$t("market_vision"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("Basic", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == 0,
                        expression: "tab == 0",
                      },
                    ],
                    attrs: { Thesis: _vm.thesis },
                  }),
                  _vm.thesis.Id
                    ? _c("Highlights", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tab == 1,
                            expression: "tab == 1",
                          },
                        ],
                      })
                    : _vm._e(),
                  _vm.thesis.Id
                    ? _c("Files", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tab == 2,
                            expression: "tab == 2",
                          },
                        ],
                      })
                    : _vm._e(),
                  _vm.thesis.Id
                    ? _c("MediaDXAVision", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tab == 3,
                            expression: "tab == 3",
                          },
                        ],
                        attrs: { MediaType: 0 },
                      })
                    : _vm._e(),
                  _vm.thesis.Id
                    ? _c("MediaMarketVision", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tab == 4,
                            expression: "tab == 4",
                          },
                        ],
                        attrs: { MediaType: 1 },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }