import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "mt-5",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.checkForm()
        },
      },
    },
    [
      _c(VTextField, {
        staticClass: "mt-2",
        attrs: {
          label: _vm.$t("name"),
          rules: [_vm.required],
          "data-test": "Thesis:Basic:Name",
        },
        model: {
          value: _vm.thesis.Name,
          callback: function ($$v) {
            _vm.$set(_vm.thesis, "Name", $$v)
          },
          expression: "thesis.Name",
        },
      }),
      _vm.thesis.Overview
        ? _c(VTextarea, {
            staticClass: "mt-2",
            attrs: {
              label: _vm.$t("overall"),
              "auto-grow": "",
              outlined: "",
              rows: "5",
              rules: [_vm.required],
              "data-test": "Thesis:Basic:Overview",
            },
            model: {
              value: _vm.thesis.Overview[_vm.$i18n.locale],
              callback: function ($$v) {
                _vm.$set(_vm.thesis.Overview, _vm.$i18n.locale, $$v)
              },
              expression: "thesis.Overview[$i18n.locale]",
            },
          })
        : _vm._e(),
      _c(VTextField, {
        staticClass: "mt-2",
        attrs: { "data-test": "Thesis:Basic:Sector", label: _vm.$t("sector") },
        model: {
          value: _vm.thesis.Sector,
          callback: function ($$v) {
            _vm.$set(_vm.thesis, "Sector", $$v)
          },
          expression: "thesis.Sector",
        },
      }),
      _c(VTextField, {
        staticClass: "mt-2",
        attrs: { "data-test": "Thesis:Basic:Website", label: _vm.$t("site") },
        model: {
          value: _vm.thesis.Website,
          callback: function ($$v) {
            _vm.$set(_vm.thesis, "Website", $$v)
          },
          expression: "thesis.Website",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("thesis_showcased")))]),
          _c(
            VRadioGroup,
            {
              attrs: { center: "", row: "" },
              model: {
                value: _vm.thesis.Showcased,
                callback: function ($$v) {
                  _vm.$set(_vm.thesis, "Showcased", $$v)
                },
                expression: "thesis.Showcased",
              },
            },
            [
              _c(VRadio, {
                attrs: {
                  "data-test": "Thesis:Basic:ShowPipelineYes",
                  label: _vm.$t("yes"),
                  value: true,
                },
              }),
              _c(VRadio, {
                attrs: {
                  "data-test": "Thesis:Basic:ShowPipelineNo",
                  label: _vm.$t("no"),
                  value: false,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.pipeline_index_options.length > 0
        ? _c(VSelect, {
            staticClass: "mt-2",
            attrs: {
              label: _vm.$t("pipeline_index"),
              items: _vm.pipeline_index_options,
              outlined: "",
            },
            model: {
              value: _vm.thesis.PipelineIndex,
              callback: function ($$v) {
                _vm.$set(_vm.thesis, "PipelineIndex", $$v)
              },
              expression: "thesis.PipelineIndex",
            },
          })
        : _vm._e(),
      _vm.thesis.InvestmentConditionsIntro
        ? _c(
            "div",
            { attrs: { rows: "5" } },
            [
              _c("span", { staticClass: "introduction-text-label" }, [
                _vm._v(_vm._s(_vm.$t("introduction") + ":")),
              ]),
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: { "data-test": "Thesis:Basic:Introduction" },
                model: {
                  value: _vm.thesis.InvestmentConditionsIntro[_vm.$i18n.locale],
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.thesis.InvestmentConditionsIntro,
                      _vm.$i18n.locale,
                      $$v
                    )
                  },
                  expression: "thesis.InvestmentConditionsIntro[$i18n.locale]",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.thesis.InvestmentConditionsText
        ? _c(
            "div",
            { staticClass: "mt-10", attrs: { rows: "5" } },
            [
              _c("span", { staticClass: "condition-text-label" }, [
                _vm._v(_vm._s(_vm.$t("text") + ":")),
              ]),
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: { "data-test": "Thesis:Basic:Text" },
                model: {
                  value: _vm.thesis.InvestmentConditionsText[_vm.$i18n.locale],
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.thesis.InvestmentConditionsText,
                      _vm.$i18n.locale,
                      $$v
                    )
                  },
                  expression: "thesis.InvestmentConditionsText[$i18n.locale]",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.thesis.IntroVideo
        ? _c(VTextField, {
            staticClass: "mt-10 pt-0",
            attrs: {
              type: "number",
              label: _vm.$t("intro_video"),
              hint: _vm.$tc("vimeo_hint", 1),
            },
            model: {
              value: _vm.thesis.IntroVideo[_vm.$i18n.locale],
              callback: function ($$v) {
                _vm.$set(_vm.thesis.IntroVideo, _vm.$i18n.locale, $$v)
              },
              expression: "thesis.IntroVideo[$i18n.locale]",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("span", { staticClass: "mb-3 ml-1 btn-choose-img-title" }, [
            _vm._v(_vm._s(_vm.$t("logo"))),
          ]),
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _vm.thesis.Base64Logo != null
                ? _c(VImg, {
                    staticClass: "ml-3",
                    attrs: {
                      src: _vm.thesis.Base64Logo,
                      "max-height": "5rem",
                      "max-width": "5rem",
                    },
                  })
                : _vm.thesis.Logo != null
                ? _c(VImg, {
                    staticClass: "ml-3",
                    attrs: {
                      src: _vm.gs.get_photo_path(_vm.thesis.Logo),
                      "max-height": "5rem",
                      "max-width": "5rem",
                    },
                  })
                : _vm._e(),
              _c("input", {
                ref: "imageLogoInput",
                staticClass: "ml-4",
                attrs: {
                  id: "imageLogoInput",
                  name: "imageLogoInput",
                  type: "file",
                  accept: "image/png, image/jpeg, image/bmp",
                },
                on: { change: _vm.setImageLogo },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-6 mb-2" },
        [
          _c("span", { staticClass: "mb-3 ml-1 btn-choose-img-title" }, [
            _vm._v(_vm._s(_vm.$t("banner"))),
          ]),
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              this.banner && this.banner[_vm.$i18n.locale]
                ? _c(VImg, {
                    staticClass: "ml-3",
                    attrs: {
                      src: _vm.gs.get_photo_path(
                        this.banner[_vm.$i18n.locale] +
                          "?t=" +
                          Math.random().toString(5)
                      ),
                      "max-height": "5rem",
                      "max-width": "5rem",
                    },
                  })
                : _vm._e(),
              _c("input", {
                ref: "imageBannerInput",
                staticClass: "ml-4",
                attrs: {
                  id: "imageBannerInput",
                  name: "imageBannerInput",
                  type: "file",
                  accept: "image/png, image/jpeg, image/bmp",
                },
                on: { change: _vm.setImageBanner },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VCardActions,
        { staticClass: "pl-0 dxa_modal_actions" },
        [
          _c(
            VBtn,
            {
              staticClass: "dxa_modal_btnSuccess",
              attrs: {
                "data-test": "Thesis:Basic:BtnSaveThesis",
                color: "primary",
                "min-width": "100",
                loading: _vm.loading,
                type: "submit",
              },
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          ),
          _c(
            VBtn,
            {
              staticClass: "dxa_modal_btnError",
              attrs: { color: "secondary", "min-width": "100" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("go_back")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }