import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "text-center mt-8" },
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                rounded: "",
                fab: "",
                top: "",
                right: "",
                absolute: "",
              },
              on: {
                click: function ($event) {
                  return _vm.add_highlight_dialog()
                },
              },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
        ],
        1
      ),
      _c(VSimpleTable, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("text")))]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("edit"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("remove"))),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.highlights, function (highlight, index) {
            return _c("tr", { key: index }, [
              _c("td", [
                _vm.gs.isJson(highlight.Text)
                  ? _c(
                      "div",
                      _vm._l(
                        Object.keys(JSON.parse(highlight.Text)),
                        function (k, i) {
                          return _c("p", { key: i, staticClass: "mb-1" }, [
                            _c(
                              "b",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(k.toUpperCase() + ": "))]
                            ),
                            _vm._v(
                              _vm._s("" + JSON.parse(highlight.Text)[k]) + " "
                            ),
                          ])
                        }
                      ),
                      0
                    )
                  : _c("span", [_vm._v(_vm._s(highlight.Text))]),
              ]),
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "px-2 ml-1 secondary",
                      attrs: { "min-width": "0", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.edit_highlight_dialog(highlight)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-pencil"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "px-2 ml-1",
                      attrs: { color: "red", "min-width": "0", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.open_delete_dialog(highlight)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-trash-can"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _vm.dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("ManageHighlight", {
                attrs: {
                  AvailableLangs: _vm.availableLangs,
                  Highlight: _vm.highlight,
                  HighlightCount: _vm.highlights.length,
                },
                on: {
                  close: function ($event) {
                    _vm.dialog = false
                  },
                  save: _vm.save_highlight,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: { obj: _vm.delete_obj, name: _vm.$t("this_highlight") },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.remove_highlight,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }