import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(_vm._s(_vm.$t("manage_media"))),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "mt-5",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.check_form()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "mx-2" },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.index_options,
                      label: _vm.$t("index"),
                      outlined: "",
                    },
                    model: {
                      value: _vm.media.Index,
                      callback: function ($$v) {
                        _vm.$set(_vm.media, "Index", $$v)
                      },
                      expression: "media.Index",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { rules: [_vm.required], label: _vm.$t("title") },
                    model: {
                      value: _vm.media.Title,
                      callback: function ($$v) {
                        _vm.$set(_vm.media, "Title", $$v)
                      },
                      expression: "media.Title",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { rules: [_vm.required], label: _vm.$t("video_id") },
                    model: {
                      value: _vm.media.VideoId,
                      callback: function ($$v) {
                        _vm.$set(_vm.media, "VideoId", $$v)
                      },
                      expression: "media.VideoId",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("media_type")))]),
                      _c(
                        VRadioGroup,
                        {
                          attrs: { center: "", row: "", disabled: "" },
                          model: {
                            value: _vm.media.Type,
                            callback: function ($$v) {
                              _vm.$set(_vm.media, "Type", $$v)
                            },
                            expression: "media.Type",
                          },
                        },
                        _vm._l(_vm.media_options, function (type, i) {
                          return _c(VRadio, {
                            key: i,
                            attrs: { label: type, value: i },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("media_plataform")))]),
                      _c(
                        VRadioGroup,
                        {
                          attrs: { center: "", row: "" },
                          model: {
                            value: _vm.media.Plataform,
                            callback: function ($$v) {
                              _vm.$set(_vm.media, "Plataform", $$v)
                            },
                            expression: "media.Plataform",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: {
                              label: _vm.$t("vimeo"),
                              value: _vm.videoPlatformEnum.VIMEO,
                            },
                          }),
                          _c(VRadio, {
                            attrs: {
                              label: _vm.$t("youtube"),
                              value: _vm.videoPlatformEnum.YOUTUBE,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        color: "primary",
                        disabled: !_vm.media.Title || !_vm.media.VideoId,
                        "min-width": "100",
                        loading: _vm.loading,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }